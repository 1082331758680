import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import '../assets/css/layout/footer.css';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';

// import { Dropdown } from "react-bootstrap";
import icline from '../assets/img/icon-line.svg';
import iline from '../assets/img/i-line.svg';
import itel from '../assets/img/i-tel.svg';
import ifb from '../assets/img/i-fb.svg';

import { Link } from "react-tiger-transition";

const apiUrl = process.env.REACT_APP_API_URL;

const options = [
    { name: "รับซื้ออุปกรณ์ก่อสร้าง" },
    { name: "แจ็คเบส" },
    { name: "ตัวตัดถนน" },
    { name: "กว้านลิฟท์" },
    { name: "สว่านหินเจีย" },
    { name: "เหล็กกล่อง" },
    { name: "เครื่องมือก่อสร้างทุกชนิด" },
    { name: "ป๊อปค้ำยัน" },
    { name: "แท่นตบดิน" },
    { name: "รถโฟล์คลิฟท์" },
    { name: "มอเตอร์" },
    { name: "เหล็กแป๊บ" },
    { name: "นั่งร้าน" },
    { name: "เครื่องดัดเครื่องตัดนิมุท" },
    { name: "ตัวขัดมัน" },
    { name: "รถแบคโฮ" },
    { name: "รถบดเดินตาม" },
    { name: "เหล็กแผ่น" },
    { name: "แบบเหล็ก" },
    { name: "โม่ปูน" },
    { name: "แมงปอ" },
    { name: "เครื่องพ่นปูนฉาบ" },
    { name: "เครื่องยนต์เบนซิน-ดีเซล" },
    { name: "เหล็กบีม" },
    { name: "ยูเอส" },
    { name: "โม่ฉาบ" },
    { name: "ลิฟท์กระป๋อง" },
    { name: "เครื่องมือช่าง" },
    { name: "เหล็กรูปพรรณ" },
    { name: "เศษเหล็ก" },
];

const Footer = (props) => {

    let history = useHistory();

    const [active, setActive] = useState(props.page);

    const toggleMenu = (data) => {
        setActive(data)
    }
    return (
        <>
            <div style={{ background: "rgb(237, 237, 237)" }}>
                <div className="container">
                    <div className="row align-items-center py-5">
                        <div className="col-12 col-md-9 col-lg-9 text-start">
                            <h1>ติดต่อเรา</h1>
                            <div>
                                {props.data.address} <br /><br />โทร : {props.data.tel} (คุณต้น)<br />
                                {/* Line ID :  @{props.data.line} <br /> */}
                                {/* Email :  {props.data.email} */}
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 text-center">
                            <div className=''>
                                <img src={apiUrl + "/uploads/config/" + props.data.id + "/" + props.data.qrcode} alt="" className="px-5 pt-5 px-md-0 px-xl-5 w-qr" style={{}} />
                                <div className='mt-3'>
                                    <a href={props.data.line} target="_blank" className="btn-line w-100" >
                                        <object type="image/svg+xml" data={icline} alt="" className="icon icon-line" style={{ height: "17px", verticalAlign: "middle" }} />
                                        <span className='ms-2'>@smtec2hand</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_home bg- padding90 pt-4" style={{ background: "#000" }}>
                <div>
                    <div className="row mt-2 shadow-sm">
                        <div className="col-12 col-md-9 col-lg-9 ps-xl-0">
                            <div className='text-start color-white mb-2 ps-0 ps-md-2'>
                                สินค้าและบริการ
                            </div>
                            <div className="row ps-0 ps-md-2">
                                {
                                    options.map((index, val) =>
                                        <div className="col-6 col-md-4 col-lg-2" key={val}>
                                            <div className='text-start font-14' style={{ color: "#666666" }}>{index.name}</div>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3">
                            <div className='text-start color-white mt-3 mt-md-0 mb-2'>
                                ติดต่อสอบถาม
                            </div>
                            <div className='text-start'>
                                {/* <a href={"https://line.me/ti/p/%40" + props.data.line} target="_blank">
                                    <img src={iline} alt="" className="icon-menu-footer" />
                                </a>
                                <a href={props.data.facebook} target="_blank" className="mx-2">
                                    <img src={ifb} alt="" className="icon-menu-footer" />
                                </a> */}
                                {/* <a href={"tel:" + props.data.tel} style={{ textDecoration:"none",color:"#fff" }}>
                                    <img src={itel} alt="" className="icon-menu-footer" /> <span className='font-14'>{props.data.tel}</span>
                                </a> */}
                                <a href={ "tel:"+props.data.tel } className="btn-tel ms-0 mt-3" style={{ padding:"5px 10px" }}>
                                    <object type="image/svg+xml" data={itel} alt="" className="icon icon-line" style={{ height: "30px", verticalAlign: "middle" }} />
                                    <span className='ms-2'>{props.data.tel}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-start py-2 mt-4' style={{ borderTop: "1px solid #666666" }}>
                    <div className='color-white d-inline-block font-14'>{props.data.copyright}</div>
                    <div className='float-end position-relative d-none d-md-block'>
                        <ul className='footer-menu'>
                            <li>
                                <Link to="/" transition='default-fade' onClick={() => toggleMenu("home")} className={active === "home" ? "active" : {}}>หน้าแรก</Link>
                            </li>
                            <li>
                                <Link to="/product/0" transition='default-fade' onClick={() => toggleMenu("product")} className={active === "product" ? "active" : {}}>สินค้าที่รับซื้อ</Link>
                            </li>
                            <li>
                                <Link to="/products/0" transition='default-fade' onClick={() => toggleMenu("products")} className={active === "products" ? "active" : {}}>สินค้าของเรา</Link>
                            </li>
                            <li>
                                <Link to="/contact" transition='default-fade' onClick={() => toggleMenu("contact")} className={active === "contact" ? "active" : {}}>ติดต่อเรา</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Footer;