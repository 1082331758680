import React from 'react';
import { Modal } from "react-bootstrap";

import loadingmail from '../assets/img/mail-download.gif';
import close from '../assets/img/close.svg';

// const apiUrl = 'https://smtec2hand.ssd-project.com/backend';
const apiUrl = process.env.REACT_APP_API_URL;

const Modalloading = (data) => {

    // console.log(data);
    // const { t } = useTranslation();

    return (
        <>
            <Modal {...data} className="modalimg" aria-labelledby="contained-modal-title-vcenter" backdrop="static"  keyboard={false} centered>
                <Modal.Body>
                    {/* <div className='text-end pb-2 cursor position-absolute' style={{ top:"-22px",right:"15px" }} onClick={data.onHide}>
                        <img src={close} alt="" className="" style={{ width:"30px" }}/>
                    </div> */}
                    <div className='bg-white text-center' style={{ borderRadius:"8px" }}>
                        <img src={ loadingmail } alt="" className="w-50 yours-custom-class p-5"/>
                    </div>
                </Modal.Body>
                
            </Modal>

        </>
    );
};

export default Modalloading;