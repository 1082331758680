import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
// import { NavLink } from 'react-router-dom';
// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import '../assets/css/menu.css';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';

// import logo from '../assets/img/logo2.svg';
import iline from '../assets/img/icon-line.svg';
import itel from '../assets/img/icon-tel.svg';

import iline2 from '../assets/img/i-line.svg';
import itel2 from '../assets/img/i-tel.svg';
import ifb2 from '../assets/img/i-fb.svg';

// import { slide as Menu } from 'react-burger-menu';
import { Link } from "react-tiger-transition";

// const apiUrl = 'http://smtec2hand.ssd-project.com/backend';
// const apiUrl = 'http://smtec2hand.com/backend';

const apiUrl = process.env.REACT_APP_API_URL;

const Header = (props) => {

    // console.log(apiUrl)

    // console.log(props.page)
    // const activeStyle = { color: '#ff3333' };

    

    const [active, setActive] = useState(props.page);

    const toggleMenu = (data) => {
        setActive(data)
    }

    const [menuOpen, setMenuOpen] = useState(false);

    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen)
    }

    const toggleMenures = () => {
        setMenuOpen(false)
    }
    

    // const [sidebar, setSidebar] = useState(false);
    // const { t } = useTranslation();

    return (
        <>
            <header className='d-none d-md-block'>
                <div className="bg-white shadow-sm padding90">
                    <div className="text-start py-2">
                        <Link to="/" transition="default-fade" className="btn-link" >
                            <img src={ apiUrl + "/uploads/config/"+ props.data.id + "/" + props.data.logo } alt="" className="logo-header" />
                        </Link>
                        <div className='pt-1 float-end position-relative text-end'>
                            <a href={props.data.line} target="_blank" className="btn-line">
                                <object type="image/svg+xml" data={iline} alt="" className="icon icon-line" style={{ height: "17px", verticalAlign: "middle" }} />
                                <span className='ms-2'>@smtec2hand</span>
                            </a>
                            <a href={ "tel:"+props.data.tel } className="btn-tel ms-2">
                                <object type="image/svg+xml" data={itel} alt="" className="icon icon-line" style={{ height: "15px", verticalAlign: "middle" }} />
                                <span className='ms-2'>{props.data.tel}</span>
                            </a>
                            <div className='text-end pt-2 mt-2'>
                                <ul className='menu-header'>
                                    <li>
                                        <Link to="/" transition='default-fade' onClick={() => toggleMenu("home")} className={active === "home" ? "active" : {}} >หน้าแรก</Link>
                                    </li>
                                    <li>
                                        <Link to="/product/0" transition='default-fade' onClick={() => toggleMenu("product")} className={active === "product" ? "active" : {}}>สินค้าที่รับซื้อ</Link>
                                    </li>
                                    <li>
                                        <Link to="/products" transition='default-fade' onClick={() => toggleMenu("products")} className={active === "products" ? "active" : {}}>สินค้าของเรา</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact" transition='default-fade' onClick={() => toggleMenu("contact")} className={active === "contact" ? "active" : {}}>ติดต่อเรา</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </header>
            
            <div className='d-block d-md-none menu-res shadow-sm'>
                <div className='text-start' style={{ padding: "3px 10px" }}>
                    <Link to="/" transition="default-fade" className="btn-link d-inline-block p-1" >
                        <img src={ apiUrl + "/uploads/config/"+ props.data.id + "/" + props.data.logo } alt="" className="logo-header" />
                    </Link>
                </div>
                <div className='right' >

                    <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"} isOpen={menuOpen} onStateChange={(state) => handleStateChange(state)}>
                        <div onClick={() => toggleMenures()} className='my-3 text-end px-3 font-20 color-white'>
                            <i className="fa fa-times"></i>
                        </div>
                        <ul className='menu-m'>
                            <li>
                                <Link to="/" transition='default-fade' onClick={() => toggleMenu("home")} className={active === "home" ? "active" : {}} >หน้าแรก</Link>
                            </li>
                            <li>
                                <Link to="/product/0" transition='default-fade' onClick={() => toggleMenu("product")} className={active === "product" ? "active" : {}}>สินค้าที่รับซื้อ</Link>
                            </li>
                            <li>
                                <Link to="/products" transition='default-fade' onClick={() => toggleMenu("products")} className={active === "products" ? "active" : {}}>สินค้าของเรา</Link>
                            </li>
                            <li>
                                <Link to="/contact" transition='default-fade' onClick={() => toggleMenu("contact")} className={active === "contact" ? "active" : {}}>ติดต่อเรา</Link>
                            </li>
                        </ul>
                        <div className='text-center mt-5'>                           
                            {/* <a href={"https://line.me/ti/p/%40" + props.data.line} target="_blank">
                                <img src={iline2} alt="" className="icon-menu-footer" />
                            </a>
                            <a href={props.data.facebook} target="_blank" className="mx-2">
                                <img src={ifb2} alt="" className="icon-menu-footer" />
                            </a> */}
                            <a href={"tel:" + props.data.tel} >
                                <img src={itel2} alt="" className="icon-menu-footer" />
                            </a>
                        </div>
                    </Menu>

                </div>
            </div>

        </>
    );
};

export default Header;