import React from 'react';
import { Modal } from "react-bootstrap";

import p1 from '../assets/img/p1.png';
import close from '../assets/img/close.svg';

// const apiUrl = 'http://smtec2hand.ssd-project.com/backend';
const apiUrl = process.env.REACT_APP_API_URL;

const Modalimg = (data) => {

    // console.log(data);
    // const { t } = useTranslation();

    return (
        <>
            <Modal {...data} className="modalimg" aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Body>
                    <div className='text-end pb-2 cursor position-absolute' style={{ top:"-22px",right:"15px" }} onClick={data.onHide}>
                        <img src={close} alt="" className="" style={{ width:"30px" }}/>
                    </div>
                    <div>
                        <img src={ apiUrl + "/uploads/"+ data.type +"/" + data.id + "/" + data.name } alt="" className="w-100 yours-custom-class" style={{ borderRadius:"8px" }}/>
                    </div>
                </Modal.Body>
                
            </Modal>

        </>
    );
};

export default Modalimg;