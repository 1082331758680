import React, { useState, useEffect } from 'react';
// import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
// import ImageUploading from 'react-images-uploading';
import axios from 'axios';

import Header from '../layout/header';
import Footer from '../layout/footer';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import qr from '../assets/img/qr-code.svg';
// import iline from '../assets/img/icon-line.svg';
// import iupload from '../assets/img/icon-upload.svg';
// import close from '../assets/img/close.svg';

import { Form, Nav, Tab } from "react-bootstrap";
// import { Link } from "react-tiger-transition";

// import Uploadfile from "../components/uploadfile";
// import Fileuploader from "../components/fileUploader";
import Modalimg from "../components/modalimg";
import Modalloading from "../components/modalloading";

// const apiUrl = 'http://smtec2hand.ssd-project.com/backend';
const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = 'http://localhost/smtec2hand-backend';

const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjMwMDAiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjMwMDAiLCJzdWIiOiJTdWJqZWN0IG9mIHRoZSBKV1QiLCJpYXQiOjE2NDM5NTAwNTUsImVtYWlsIjoiYWRtaW5AZ21haWwuY29tIn0.Pgcgs2SkwbMS_tRBq0PRaOEeCCEG9jTQoC6i6zPqRHk';

const Product = () => {

    const { id } = useParams();

    const [defalutactive, setDefalutactive] = useState(id);

    // var postData = {
    //     email: "test@test.com",
    //     password: "password"
    // };
    const config = {
        "Content-type": "application/json; charset=UTF-8",
        'Authorization': "Bearer " + token,
        "x-access-token" : "123456"
    };
    // const config = {
    //     headers: {
    //       "Content-type": "application/json; charset=UTF-8",
    //       'Authorization': 'Bearer '+token,
    //        "x-access-token" : "123456"
    //     }
    //   }

    const [webdata, setWebdata] = useState([]);
    const [category, setCategory] = useState([])
    const [products, setProducts] = useState([]);

    const fetchData = async () => {
        try{
            const wed = await axios.get(`${apiUrl}/api/webdetail`, config);
            setWebdata(
                wed.data[0]
            );
        } catch (err) {
            console.log(err.message);
        }

        const ces = await axios.get(`${apiUrl}/api/category`, config);
        setCategory(
            ces.data
        );

        const res = await axios.get(`${apiUrl}/api/product`, config);
        setProducts(
            res.data
        );
    };
    useEffect(() => {
        fetchData();
        setDefalutactive(id)
    }, []);

    // const qrcode = apiUrl + "/uploads/config/" + webdata.id + "/" + webdata.qrcode;

    // let history = useHistory();
    

    const [modalimg, setModalimg] = useState({ view: false, id: 0, name: "" });

    const [modalloading, setModalloading] = useState({ view: false });

    // const [validated, setValidated] = useState(false);

    // const [name, setName] = useState("");

    // const [images, setImages] = useState([]);

    // const maxNumber = 10;
    // const maxFileSize = 20971520; //byte

    // const onChange = (imageList, addUpdateIndex) => {
    //     setImages(imageList);
    // };

    // const handleSubmit = (event) => {

    //     event.preventDefault();

    //     const form = event.currentTarget;

    //     const data = new FormData(event.target);
    //     // const formData = new FormData();

    //     data.append("file", JSON.stringify(images));

    //     if (form.checkValidity() === false) {

    //         event.preventDefault();
    //         event.stopPropagation();

    //     } else {
    //         setModalloading({ view: true })
    //         // alert(data.get('fullname'));
    //         // alert(data.get('budget'));
    //         // history.push("/myprofile");
    //         // alert(555);
    //         // axios.post("http://localhost/smtec2hand-backend/sendmail", data)

    //         axios.post("http://smtec2hand.com/backend/sendmail", data)
    //             .then((res) => {
    //                 // alert(res.data.success);
    //                 if (res.data.success === true) {
    //                     setModalloading({ view: false });
    //                     window.location.reload(true);
    //                 }

    //             })
    //             .catch((err) => alert("File Upload Error"));

    //     }
    //     setValidated(true);
    // };

    

    return (
        <>
            {/* box header  */}
            <Header page="product" data={webdata} />

            <Tab.Container id="left-tabs-example" defaultActiveKey={defalutactive}>

                <div className="wrapper box-index" style={{ backgroundColor: "#fff" }}>

                    <section className="" >
                        <div className='text-center box-list-col topic-res mt-5 pt-5'>
                            <h1 className='my-5'>สินค้าที่รับซื้อ</h1>
                        </div>
                        <div className='px-2'>
                            <div className="">
                                <Nav as="ul" variant="tabs" className="tab-product justify-content-start justify-content-md-center">
                                    {category.sort((a, b) => b.id - a.id).map((index, val) =>
                                        <Nav.Item as="li" key={val}>
                                            <Nav.Link eventKey={index.id}>{index.name}</Nav.Link>
                                        </Nav.Item>
                                    )}
                                </Nav>
                            </div>
                            <div className='mt-5 mb-5'>
                                <Tab.Content style={{}}>
                                    <Tab.Pane eventKey="0">
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-6 col-lg-6 text-start">
                                                    <div className="row">
                                                        {products.sort((a, b) => b.id - a.id).map((index, val) =>
                                                            <div className="col-6 col-md-6 col-lg-6 mb-4 pb-3" key={val}>
                                                                <div className='box-img-product bg-img cursor' onClick={() => setModalimg({ view: true, id: index.id, name: index.img })}
                                                                    style={{ backgroundImage: `url(${apiUrl + "/uploads/product/" + index.id + "/" + index.img})` }}>
                                                                </div>
                                                                <div className='mt-3'><h5>{index.name}</h5></div>

                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>


                                    {category.sort((a, b) => b.id - a.id).map((index, val) =>
                                        <Tab.Pane eventKey={index.id} key={val}>
                                            <div className="container">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-6 col-lg-6 text-start">
                                                        <div className="row">
                                                            {
                                                                products.filter(catid => catid.cat_id === index.id).sort((a, b) => b.id - a.id).map((index2, val2) =>
                                                                    <div className="col-6 col-md-6 col-lg-6 mb-4 pb-3" key={val2}>
                                                                        <div className='box-img-product bg-img cursor' onClick={() => setModalimg({ view: true, id: index2.id, name: index2.img })}
                                                                            style={{ backgroundImage: `url(${apiUrl + "/uploads/product/" + index2.id + "/" + index2.img})` }}>
                                                                        </div>
                                                                        <div className='mt-3'><h5>{index2.name}</h5></div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className='mt-5'>
                                                            <h3>รายละเอียด</h3>
                                                            {index.detail}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    )}
                                </Tab.Content>
                            </div>
                        </div>
                    </section>
                </div>
            </Tab.Container>
            <Footer page="product" data={webdata} />

            <Modalimg show={modalimg.view} onHide={() => setModalimg({ view: false, id: 0 })} id={modalimg.id} name={modalimg.name} type="product"/>

            <Modalloading show={modalloading.view} onHide={() => setModalloading({ view: false })} />

        </>
    );
};

export default Product;