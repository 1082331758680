import React, { useState, useEffect } from 'react';
// import { Dropdown } from "react-bootstrap";
// import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';
// import { Link } from 'react-router-dom';

import Slider from "react-slick";

import Header from '../layout/header';
import Footer from '../layout/footer';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bghome from '../assets/img/bg-home.jpg';
import detail from '../assets/img/detail.svg';
import iline from '../assets/img/icon-line.svg';

// import cat1 from '../assets/img/cat1.svg';

import bgcat from '../assets/img/bg-cat.svg';
import bglist from '../assets/img/bg-list.png';

import list1 from '../assets/img/list1.png';
import list2 from '../assets/img/list2.png';
import list3 from '../assets/img/list3.png';

import img1 from '../assets/img/img1.png';
import img2 from '../assets/img/img2.png';
import img3 from '../assets/img/img3.png';
import img4 from '../assets/img/img4.png';
import img5 from '../assets/img/img5.png';
import img6 from '../assets/img/img6.png';
// state = { open: false };

// toggleMenu = () => {
//     this.setState({ open: !this.state.open });
//     console.log(this.state.open);
// };
import { Link } from "react-tiger-transition";

// const apiUrl = 'http://smtec2hand.ssd-project.com/backend';

const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {

    const headers = {
        'Authorization': 'Bearer my-token',
        'My-Custom-Header': 'foobar'
    };

    const [webdata, setWebdata] = useState([]);
    const [category, setCategory] = useState([]);

    const fetchData = async () => {

        const wed = await axios.get(`${apiUrl}/api/webdetail`, headers);
        setWebdata(
            wed.data[0]
        );

        const ces = await axios.get(`${apiUrl}/api/category`, headers);
        setCategory(
            ces.data
        );
    };
    useEffect(() => {
        fetchData();
    }, []);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerPadding: "60px",
    };

    // console.log(webdata)

    return (
        <>
            {/* box header  */}
            <div id="outer-container">

                <Header page="home" data={webdata} />

                <div className="wrapper box-index" style={{ backgroundColor: "#fff" }}>

                    <section className="" >
                        <div className='position-relative'>
                            <img src={bghome} alt="" className="w-100 d-none d-md-block" />
                            <div className='text-banner-home text-start'>
                                <div className="container">
                                    <div className='topic-banner'>มั่นใจ ซื้อ-ขายกับเรา <br />เราให้ราคาสูงกว่า</div>
                                    <div className='text-banner mt-3 mt-md-3 mt-xl-5'>
                                        รับซื้ออุปกรณ์ก่อสร้าง, ชุดนั่งร้าน เหล็กป๊อปและอื่นๆ
                                        พร้อมด้วยเครื่องมือ, รับซื้อเศษเหล็กทุกชนิด รื้อโรงงานเศษเหล็ก
                                        ด้วยทีมงานมืออาชีพที่มีประสบการณ์มากกว่า 40 ปี
                                    </div>
                                    <div className='mt-4'>
                                        <a href={"tel:" + webdata.tel} target="_blank" className="btn btn-orang rounded-pill px-4">
                                            <img src={detail} alt="" className="" style={{ width: "25px" }} /> สอบถามรายละเอียด
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container box-img-cat" >
                            <div className="row align-items-center">
                                <div className="col-3 col-md-3 col-lg-3 text-start padres">
                                    <Link to="#" className="click-img-cat">
                                        <img src={img1} alt="" className="w-100" style={{}} />
                                        {/* <div className='img-hover-box'>
                                            <div className="text-name">รถโฟล์คลิฟท์</div>
                                        </div> */}
                                    </Link>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 text-start padres">
                                    <Link to="#" className="click-img-cat">
                                        <img src={img2} alt="" className="w-100" style={{}} />
                                        {/* <div className='img-hover-box'>
                                            <div className="text-name">เครื่องเชื่อม</div>
                                        </div> */}
                                    </Link>
                                    <Link to="#" className="click-img-cat">
                                        <img src={img3} alt="" className="w-100" style={{}} />
                                        {/* <div className='img-hover-box'>
                                            <div className="text-name">อุปกรณ์นั่งร้าน</div>
                                        </div> */}
                                    </Link>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 text-start padres">
                                    <Link to="#" className="click-img-cat">
                                        <img src={img4} alt="" className="w-100" style={{}} />
                                        {/* <div className='img-hover-box'>
                                            <div className="text-name">โม่ปูน</div>
                                        </div> */}
                                    </Link>
                                    <Link to="#" className="click-img-cat">
                                        <img src={img5} alt="" className="w-100" style={{}} />
                                        {/* <div className='img-hover-box'>
                                            <div className="text-name">เศษเหล็ก</div>
                                        </div> */}
                                    </Link>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 text-start padres">
                                    <Link to="#" className="click-img-cat">
                                        <img src={img6} alt="" className="w-100" style={{}} />
                                        {/* <div className='img-hover-box'>
                                            <div className="text-name">เครื่องเชื่อม</div>
                                        </div> */}
                                    </Link>
                                </div>
                            </div>
                            <div className='text-center mt-5'>
                                <Link to="/product/0" transition='default-fade' className="btn btn-green">ดูเพิ่มเติม</Link>
                            </div>
                        </div>
                        <div className="bg-img position-relative" style={{ backgroundImage: `url(${bglist})` }}>
                            <div className='position-absolute d-none d-md-block' style={{ top: "52%", width: "100%" }}><hr style={{ borderTop: "3px dotted #000", backgroundColor: "unset" }} /></div>
                            <div className="container box-list" >
                                <div className='text-start box-list-col topic-res'>
                                    <h1 className='my-5'>ดำเนินการ<br />เพียง 3 ขั้นตอน</h1>
                                </div>
                                <div className="row d-none d-md-flex">
                                    <div className="col-12 col-md-4 col-lg-4 text-start box-list-col">
                                        <div className='position-relative'>
                                            <img src={list1} alt="" className="w-100 img-list-box" style={{}} />
                                            <span className='position-absolute box-number'>
                                                1
                                            </span>
                                        </div>
                                        <div className='font-18 mt-4'>
                                            ถ่ายรูปสินค้า <br />
                                            หรือรายละเอียดหน้างาน
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 text-start box-list-col">
                                        <div className='position-relative'>
                                            <img src={list2} alt="" className="w-100 img-list-box" style={{}} />
                                            <span className='position-absolute box-number'>
                                                2
                                            </span>
                                        </div>
                                        <div className='font-18 mt-4'>
                                            ส่งประเมินราคา<br />
                                            พูดคุยรายละเอียด
                                        </div>
                                        {/* <div className='mt-3'>
                                            <a href={"https://line.me/ti/p/%40" + webdata.line} target="_blank" className="btn-line w-100" >
                                                <object type="image/svg+xml" data={iline} alt="" className="icon icon-line" style={{ height: "17px", verticalAlign: "middle" }} />
                                                <span className='ms-2'>@{webdata.line}</span>
                                            </a>
                                        </div> */}
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 text-start box-list-col">
                                        <div className='position-relative'>
                                            <img src={list3} alt="" className="w-100 img-list-box" style={{}} />
                                            <span className='position-absolute box-number'>
                                                3
                                            </span>
                                        </div>
                                        <div className='font-18 mt-4'>
                                            ดำเนินการ<br />
                                            ออกหน้างาน
                                        </div>
                                    </div>
                                </div>
                                <div className='d-block d-md-none'>
                                    <Slider {...settings}>
                                        <div className="px-5">
                                            <div className='position-relative'>
                                                <img src={list1} alt="" className="w-100 img-list-box" style={{}} />
                                                <span className='position-absolute box-number'>
                                                    1
                                                </span>
                                            </div>
                                            <div className='font-18 mt-4'>
                                                ถ่ายรูปสินค้า <br />
                                                หรือรายละเอียดหน้างาน
                                            </div>
                                        </div>
                                        <div className="px-5">
                                            <div className='position-relative'>
                                                <img src={list2} alt="" className="w-100 img-list-box" style={{}} />
                                                <span className='position-absolute box-number'>
                                                    2
                                                </span>
                                            </div>
                                            <div className='font-18 mt-4'>
                                                ส่งประเมินราคา<br />
                                                พูดคุยรายละเอียด
                                            </div>
                                            <div className='mt-3'>
                                                <a href={"https://line.me/ti/p/%40" + webdata.line} target="_blank" className="btn-line w-100" >
                                                    <object type="image/svg+xml" data={iline} alt="" className="icon icon-line" style={{ height: "17px", verticalAlign: "middle" }} />
                                                    <span className='ms-2'>@{webdata.line}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="px-5">
                                            <div className='position-relative'>
                                                <img src={list3} alt="" className="w-100 img-list-box" style={{}} />
                                                <span className='position-absolute box-number'>
                                                    3
                                                </span>
                                            </div>
                                            <div className='font-18 mt-4'>
                                                ดำเนินการ<br />
                                                ออกหน้างาน
                                            </div>
                                        </div>

                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className='bg-cat bg-img py-5' style={{ backgroundImage: `url(${bgcat})` }}>
                            <h3 className='color-white'>สินค้าที่รับซื้อ</h3>
                            <div className='mt-5'>
                                <ul className='cat-product justify-content-start'>
                                    {category.sort((a, b) => b.id - a.id).map((index, val) =>
                                        <li key={val}>
                                            <Link to={"/product/" + index.id} transition='default-fade'>
                                                <div className=''>
                                                    <span className='cat-cicle'>
                                                        <img src={apiUrl + "/uploads/category/" + index.id + "/" + index.icon} alt="" className="" style={{}} />
                                                    </span>
                                                </div>
                                                <div className='text-cat mt-4'>
                                                    {index.name}
                                                </div>
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>

                    </section>
                </div>
                {webdata ?
                    <Footer page="home" data={webdata} />
     
                    : null}
            </div>

        </>
    );
};

export default Home;