import React, { useState, useEffect } from 'react';
// import { Dropdown } from "react-bootstrap";
// import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';

import Header from '../layout/header';
import Footer from '../layout/footer';

import iline from '../assets/img/icon-line.svg';

// state = { open: false };

// toggleMenu = () => {
//     this.setState({ open: !this.state.open });
//     console.log(this.state.open);
// };

// const apiUrl = 'http://smtec2hand.ssd-project.com/backend';
const apiUrl = process.env.REACT_APP_API_URL;

const Contact = () => {

    const headers = {
        'Authorization': 'Bearer my-token',
        'My-Custom-Header': 'foobar'
    };

    const [webdata, setWebdata] = useState([]);

    const fetchData = async () => {

        const wed = await axios.get(`${apiUrl}/api/webdetail`, headers);
        setWebdata(
            wed.data[0]
        );
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {/* box header  */}
            <Header page="contact"  data={webdata}/>

            <div className="" style={{ backgroundColor: "#fff" }}>

                <section className="mb-0 pb-5 mb-md-5" >
                    <div className='position-relative mt-5 pt-5 d-none d-md-block'>
                        <iframe src={webdata.map} width="65%" height="400" style={{border:"0"}} allowFullScreen loading="lazy"></iframe>
                    </div>
                    <div className='position-relative mt-5 pt-5 d-block d-md-none'>
                        <iframe src={webdata.map} width="100%" height="400" style={{border:"0"}} allowFullScreen loading="lazy"></iframe>
                    </div>                   
                </section>
            </div>

            <Footer page="contact"  data={webdata}/>

        </>
    );
};

export default Contact;