import React, { useState, useEffect } from 'react';
// import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from 'axios';

import Header from '../layout/header';
import Footer from '../layout/footer';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import qr from '../assets/img/qr-code.svg';

// import { Form, Nav, Tab } from "react-bootstrap";
// import { Link } from "react-tiger-transition";

// import Uploadfile from "../components/uploadfile";
// import Fileuploader from "../components/fileUploader";
import Modalimg from "../components/modalimg";
import Modalloading from "../components/modalloading";

// const apiUrl = 'http://smtec2hand.ssd-project.com/backend';
const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = 'http://localhost/smtec2hand-backend';

const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjMwMDAiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjMwMDAiLCJzdWIiOiJTdWJqZWN0IG9mIHRoZSBKV1QiLCJpYXQiOjE2NDM5NTAwNTUsImVtYWlsIjoiYWRtaW5AZ21haWwuY29tIn0.Pgcgs2SkwbMS_tRBq0PRaOEeCCEG9jTQoC6i6zPqRHk';

const Detail = () => {

    const { id } = useParams();

    const [defalutid, setDefalutid] = useState(id);

    const config = {
        "Content-type": "application/json; charset=UTF-8",
        'Authorization': "Bearer " + token,
        "x-access-token": "123456"
    };

    const [webdata, setWebdata] = useState([]);
    const [products, setProducts] = useState([]);
    const [productsimg, setProductsimg] = useState([]);

    const fetchData = async () => {
        try {
            const wed = await axios.get(`${apiUrl}/api/webdetail`, config);
            setWebdata(
                wed.data[0]
            );
        } catch (err) {
            console.log(err.message);
        }

        const res = await axios.get(`${apiUrl}/api/products/show/` + defalutid, config);
        setProducts(
            res.data[0]
        );
        setProductsimg(
            JSON.parse(res.data[0].img)
        );
        
    };
    useEffect(() => {
        fetchData();
    }, []);

    const [modalimg, setModalimg] = useState({ view: false, id: 0, name: "" });
    const [modalloading, setModalloading] = useState({ view: false });

    return (
        <>
            {/* box header  */}
            <Header page="products" data={webdata} />


            <div className="wrapper box-index" style={{ backgroundColor: "#fff" }}>

                <section className="" >
                    <div className='text-center box-list-col topic-res mt-5 pt-5'>
                        {/* <h1 className='my-5'>สินค้าของเรา</h1> */}
                    </div>
                    <div className='px-2'>

                        <div className='mt-5 mb-5'>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-10 col-lg-10 text-start">
                                        <div className='mt-3'><h5>{products.name} </h5></div>
                                        <div className='mt-3'><h5>รายละเอียด : {products.detail}</h5> </div>
                                        <div className='mt-3'><h5>ราคา : {products.price} </h5></div>
                                        <div className='mt-3'><h5>สถานะ : {products.status == '1' ? "มีสินค้า" : "สินค้าหมด"} </h5></div>
                                        <div className="row mt-5">
                                            { productsimg.map((index, val) =>
                                                <div className="col-12 col-md-4 col-lg-4 mb-4 pb-3" key={val}>
                                                    <div className='box-img-product bg-img cursor box-img-product-res' onClick={() => setModalimg({ view: true, id: products.id, name: index })}
                                                        style={{ backgroundImage: `url(${apiUrl + "/uploads/products/" + products.id + "/" + index }` }}>
                                                    </div> 
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer page="products" data={webdata} />

            <Modalimg show={modalimg.view} onHide={() => setModalimg({ view: false, id: 0 })} id={modalimg.id} name={modalimg.name} type="products" />

            <Modalloading show={modalloading.view} onHide={() => setModalloading({ view: false })} />

        </>
    );
};

export default Detail;