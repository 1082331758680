import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/respornsive.css';

import Home from './pages/home';
import Contact from './pages/contact';
import Product from './pages/product';
import Products from './pages/products';
import Detail from './pages/detail';

import { Navigation, Route, Screen, glide, fade } from "react-tiger-transition";

import './assets/css/menu.css';

glide({
  name: 'glide-left'
});
glide({
  name: 'glide-right',
  direction: 'right'
});
fade({
  name: "default-fade"
});

function App() {

  return (
    <div className="App">
      <Router basename="">
      <Switch>
        <Navigation>
          {/* <Route exact path="/"><Screen><Loading></Loading></Screen></Route> */}
          <Route exact path="/"><Screen><Home></Home></Screen></Route>
          <Route path="/contact"><Screen><Contact></Contact></Screen></Route>
          <Route path="/product/:id"><Screen><Product></Product></Screen></Route>
          <Route path="/products"><Screen><Products></Products></Screen></Route>
          <Route path="/detail/:id"><Screen><Detail></Detail></Screen></Route>
        </Navigation>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
